export const common = {
  council: "port-stephens",
  apiToken: "0f4be038084a3664b88de3da727589c4",
  oneSignalAppID: "9bc5f411-dd07-4375-b4cb-5feab216089c",
  googleSenderID: "431520876930",
  googleApiKey: "AIzaSyD1oHprkxjFrM36HMyo3BmzI4d2_6bnNGg",
  appVersion: "1.0.4.0",
  googleDirectionsUrl: "https://maps.googleapis.com/maps/api/geocode/json",
  contactPageId: 1,
  vouchersInfoId: 45,
  defaultPageSize: 50,
  mapMarkerPath:
      "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
  firebaseConfig: {
    apiKey: "AIzaSyDDjmvAJBr8U3DGEpQwqFqh5S3X6meDbLE",
    authDomain: "portstephens-waste-info.firebaseapp.com",
    projectId: "portstephens-waste-info",
    storageBucket: "portstephens-waste-info.appspot.com",
    messagingSenderId: "785005701408",
    appId: "1:785005701408:web:6e74f0b19f69d198d64f45",
    measurementId: "G-J4REY88979"
  },
  pages: [
    {
      title: "Calendar",
      icon: "calendar",
      colour: "secondary",
      route: "/calendar",
    },
    {
      title: "A-Z",
      icon: "a-z",
      colour: "secondary",
      route: "/a-z",
    },
    {
      title: "Bin Info",
      icon: "bin-info",
      colour: "secondary",
      route: "/bin-info",
    },
    {
      title: "Forms",
      icon: "forms",
      colour: "secondary",
      route: "/more-info/info/1",
      params: {title: "Forms"}
    },
    {
      title: "Recycling Near Me",
      icon: "recycling-near-me",
      colour: "secondary",
      route: "/info-multi-map/11",
    },
    {
      title: "Missed Bin Service",
      icon: "missed-service",
      colour: "secondary",
      route: "/info-details/45",
    },
    {
      title: "Waste Facilities",
      icon: "waste-facilities",
      colour: "secondary",
      route: "/info/47",
      params: {title: "Waste Facilities"}
    },
    {
      title: "Kerbside Information",
      icon: "kerbside-info",
      colour: "secondary",
      route: "/info-details/50",
    },
    // {
    //   title: "Vouchers",
    //   icon: "voucher",
    //   colour: "secondary",
    //   route: "/vouchers",
    // },
    {
      title: "More Info",
      icon: "more-info",
      colour: "secondary",
      route: "/more-info",
    },
    {
      title: "News & Alerts",
      icon: "news",
      colour: "secondary",
      route: "/news-and-alerts",
    },
  ],
};
