<ion-app>
  <ion-menu *ngIf="!iframeService.inIframe()" contentId="main-content">
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list lines="full" id="inbox-list" class="ion-no-padding">
        <ion-menu-toggle auto-hide="false">
          <ion-item
            *ngFor="let page of menuPages; let i = index"
            (click)="selectedIndex = i"
            routerDirection="root"
            [routerLink]="[page.route]"
            [queryParams]="page.params"
            detail="false"
            [class.selected]="selectedIndex === i"
          >
            <ion-icon
              slot="start"
              color="secondary"
              [name]="page.icon"
            ></ion-icon>
            <ion-label class="ion-text-wrap">{{ page.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
